<template>
  <div class="appointments new-appointment">

    <div v-if="languages && languages.length > 1" class="lang-selector">
      <p>
        <span>{{ $t('language') }}:</span>
        <a v-for="language in languages" v-bind:key="language.id" @click="select_lang(language.slug)"
          :class="check_lang_class(language.slug)">{{ language.name }}</a>
      </p>
    </div>

    <h1>{{ $t('online_appointment') }}</h1>
    <p class="lead">{{ $t('intro') }}.</p>

    <div v-if="maintenance" class="alert alert-info">
      <h2 style="margin-top:0">Wartungsarbeiten</h2>
      <p style="line-height:1.3">Wir führen aktuell Wartungsarbeiten durch. Eine Online-Terminbuchung ist aktuell leider
        nicht möglich. Bitte versuchen Sie es später erneut, wir sind bald wieder zurück.</p>
    </div>
    <div v-else>
      <div v-if="appointment_created" class="appointment-success">
        <p>
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar-check"
            class="svg-inline--fa fa-calendar-check fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512">
            <path fill="currentColor"
              d="M400 64h-48V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v52H160V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V160h352v298a6 6 0 0 1-6 6zm-52.849-200.65L198.842 404.519c-4.705 4.667-12.303 4.637-16.971-.068l-75.091-75.699c-4.667-4.705-4.637-12.303.068-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l44.104 44.461 111.072-110.181c4.705-4.667 12.303-4.637 16.971.068l22.536 22.718c4.667 4.705 4.636 12.303-.069 16.97z">
            </path>
          </svg>
        </p>
        <h3>{{ $t('booked_successfully') }}</h3>
        <p>{{ $t('booked_successfully_detail1') }}.</p>
        <p>{{ $t('booked_successfully_detail2') }}.</p>
      </div>
      <div v-else>

        <ul v-if="appointment.location" class="nav nav-steps nav-completed">
          <li v-if="appointment.location" @click="reset_appointment('location')">
            <p>1. {{ $t('location') }}: <span v-if="appointment.location">{{ appointment.location.name }}</span></p>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle"
              class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z">
              </path>
            </svg>
            <p class="step-edit">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen"
                class="svg-inline--fa fa-pen fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
                </path>
              </svg>
              <span>{{ $t('edit') }}</span>
            </p>
          </li>
          <li v-if="appointment.category" @click="reset_appointment('category')">
            <p>2. {{ $t('category') }}: <span v-if="appointment.category">{{ appointment.category.name }}</span></p>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle"
              class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z">
              </path>
            </svg>
            <p v-if="!feature_letter_coding" class="step-edit">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen"
                class="svg-inline--fa fa-pen fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
                </path>
              </svg>
              <span>{{ $t('edit') }}</span>
            </p>
          </li>
          <li v-if="appointment.category && appointment.service && selected_services && selected_services.length > 0"
            @click="reset_appointment('service')">
            <p>3. {{ $t('service') }}: <span v-if="appointment.service && appointment.service.name">{{
              appointment.service.name }}</span></p>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle"
              class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z">
              </path>
            </svg>
            <p v-if="!feature_letter_coding" class="step-edit">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen"
                class="svg-inline--fa fa-pen fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
                </path>
              </svg>
              <span>{{ $t('edit') }}</span>
            </p>
          </li>
          <li v-if="appointment.category && appointment.service && finished_cart == true"
            @click="reset_appointment('cart')">
            <p>4. {{ $t('selected_services') }}</p>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle"
              class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z">
              </path>
            </svg>
            <p v-if="!feature_letter_coding" class="step-edit">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen"
                class="svg-inline--fa fa-pen fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
                </path>
              </svg>
              <span>{{ $t('edit') }}</span>
            </p>
          </li>
          <li v-if="!feature_letter_coding && appointment.date" @click="reset_appointment('date')">
            <p>5. {{ $t('date_time') }}: {{ appointment.date }} um {{ appointment.time }} Uhr</p>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle"
              class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z">
              </path>
            </svg>
            <p v-if="!feature_letter_coding" class="step-edit">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen"
                class="svg-inline--fa fa-pen fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
                </path>
              </svg>
              <span>{{ $t('edit') }}</span>
            </p>
          </li>
          <li v-if="feature_letter_coding && appointment.contact_details" @click="reset_appointment('contact_details')">
            <p>5. Kontaktdaten: {{ appointment.contact_details.first_name }} {{ appointment.contact_details.last_name }}
            </p>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle"
              class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z">
              </path>
            </svg>
            <p v-if="!feature_letter_coding" class="step-edit">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen"
                class="svg-inline--fa fa-pen fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
                </path>
              </svg>
              <span>{{ $t('edit') }}</span>
            </p>
          </li>
          <li v-if="feature_letter_coding && appointment.date" @click="reset_appointment('date')">
            <p>6. {{ $t('date_time') }}: {{ appointment.date }} um {{ appointment.time }} Uhr</p>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle"
              class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z">
              </path>
            </svg>
            <p v-if="!feature_letter_coding" class="step-edit">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen"
                class="svg-inline--fa fa-pen fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
                </path>
              </svg>
              <span>{{ $t('edit') }}</span>
            </p>
          </li>
          <li v-if="!feature_letter_coding && appointment.contact_details" @click="reset_appointment('contact_details')">
            <p>6. Kontaktdaten: {{ appointment.contact_details.first_name }} {{ appointment.contact_details.last_name }}
            </p>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle"
              class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z">
              </path>
            </svg>
            <p v-if="!feature_letter_coding" class="step-edit">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen"
                class="svg-inline--fa fa-pen fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
                </path>
              </svg>
              <span>{{ $t('edit') }}</span>
            </p>
          </li>
        </ul>

        <div v-if="current_step == 'location'">
          <h3>1. {{ $t('select_location') }}</h3>
          <div class="selector">
            <div v-if="loading_locations" class="loading-wrap">
              <span class="loading-spinner"></span>
            </div>
            <div v-else>
              <div v-for="location in locations" v-bind:key="location.id">
                <input type="radio" :id="location.slug" :value="location" v-model="selections.location">
                <label :for="location.slug" class="select-wrap">
                  <span class="radio-button"></span>
                  <div class="select-body">
                    <h4>{{ location.name }}</h4>
                    <p v-if="location.description" style="white-space: pre-wrap;">{{ location.description }}</p>
                    <p v-if="location.web_url">Weitere Infos finden Sie auf unserer Webseite: <a :href="location.web_url"
                        target="_blank">Hier klicken ›</a></p>
                    <p v-if="location.address">
                      <svg style="display:inline-block;vertical-align:middle;margin-right:4px;width:14px"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                        <path fill-rule="evenodd"
                          d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                          clip-rule="evenodd" />
                      </svg>
                      <span style="display:inline-block;vertical-align:middle">{{ location.address }}</span>
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <p class="meta-action">
            <a @click="set_location" class="button button-red">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                class="svg-inline--fa fa-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z">
                </path>
              </svg>
              <span>{{ $t('continue_to_category') }}</span>
            </a>
          </p>
        </div>

        <div v-if="current_step == 'category'">
          <h3>2. {{ $t('select_category') }}</h3>
          <div class="selector">
            <div v-if="loading_categories" class="loading-wrap">
              <span class="loading-spinner"></span>
            </div>
            <div v-else>

              <div v-if="appointment.location.info_body" class="alert alert-notice"
                style="text-align:left;font-size:13.5px;line-height:1.4" v-html="appointment.location.info_body"></div>

              <div class="row row-gutter-20">
                <div v-for="category in categories" v-bind:key="category.id" class="col-12">
                  <input type="radio" :id="category.slug" :value="category" v-model="selections.category">
                  <label :for="category.slug" class="select-wrap">
                    <span class="radio-button"></span>
                    <div class="select-body">
                      <h4>{{ category.name }}</h4>
                      <p v-if="category.description" style="white-space: pre-wrap;">{{ category.description }}</p>
                      <p v-if="category.web_url">Weitere Infos finden Sie auf unserer Webseite: <a
                          :href="category.web_url" target="_blank">Hier klicken ›</a></p>
                      <p v-if="category.address">
                        <svg style="display:inline-block;vertical-align:middle;margin-right:4px;width:14px"
                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                          <path fill-rule="evenodd"
                            d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                            clip-rule="evenodd" />
                        </svg>
                        <span style="display:inline-block;vertical-align:middle">{{ category.address }}</span>
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <p v-if="categories.length > 0" class="meta-action">
            <a @click="set_category" class="button button-red">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                class="svg-inline--fa fa-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z">
                </path>
              </svg>
              <span>{{ $t('continue_to_service') }}</span>
            </a>
          </p>
        </div>

        <div v-if="current_step == 'service'">
          <h3>3. {{ $t('select_service') }}</h3>
          <div class="selector">
            <div v-if="loading_services" class="loading-wrap">
              <span class="loading-spinner"></span>
            </div>
            <div v-else>
              <div v-for="service in services" v-bind:key="service.id">
                <div v-if="service.status == 'visible'">
                  <input type="radio" :id="service.id" :value="service" v-model="selections.service">
                  <label :for="service.id" class="select-wrap">
                    <span class="radio-button"></span>
                    <div class="select-body">
                      <h4>{{ service.name }}</h4>
                      <p v-if="service.description" style="white-space: pre-wrap;">{{ service.description }}</p>
                      <p v-if="service.web_url">Weitere Infos finden Sie auf unserer Webseite: <a :href="service.web_url"
                          target="_blank">Hier klicken ›</a></p>
                      <p v-if="service.multi_select_possible" class="toggle-service_amount">
                        <span @click="service.service_amount > 1 ? service.service_amount -= 1 : service.service_amount"
                          class="button-change">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus"
                            class="svg-inline--fa fa-minus" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512">
                            <path fill="currentColor"
                              d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z">
                            </path>
                          </svg>
                        </span>
                        <input v-model="service.service_amount" type="text" class="form-input" id="service_amount">
                        <span @click="service.service_amount < 4 ? service.service_amount += 1 : service.service_amount"
                          class="button-change">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus"
                            class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512">
                            <path fill="currentColor"
                              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
                            </path>
                          </svg>
                        </span>
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selected_services && selected_services.length == 0 && selections && selections.service && selections.service.online_request_url" class="alert alert-notice"
            style="text-align:left;line-height:1.4">
            <p v-if="selections.service.online_request_text">{{ selections.service.online_request_text }}</p>
            <p v-else>Die selektierte Leistung kann über einen Online-Antrag schneller bearbeitet werden. Bitte klicken
              Sie auf "Zum Online-Antrag" um den Online-Antrag auszufüllen.</p>
          </div>

          <p class="meta-action">
            <a v-if="selected_services && selected_services.length == 0 && selections && selections.service && selections.service.online_request_url"
              :href="selections.service.online_request_url" target="_blank" class="button button-gray"
              style="margin-right:10px">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                class="svg-inline--fa fa-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z">
                </path>
              </svg>
              <span>Zum Online-Antrag</span>
            </a>
            <a @click="set_service" class="button button-red">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                class="svg-inline--fa fa-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z">
                </path>
              </svg>
              <span>{{ $t('continue_to_service_overview') }}</span>
            </a>
          </p>
        </div>

        <div v-if="current_step == 'cart'">
          <h3>4. {{ $t('selected_services') }}</h3>
          <div class="selector">
            <div v-if="loading_cart" class="loading-wrap">
              <span class="loading-spinner"></span>
            </div>
            <div v-else>
              <div v-if="selected_services && selected_services.length == 0" class="alert alert-info">
                Keine Leistungen ausgewählt.
              </div>
              <div v-else>
                <div v-for="service in selected_services" v-bind:key="service.id" class="cart-entry">
                  <div v-if="service.multi_select_possible" class="form-wrap">
                    <span @click="service.service_amount > 1 ? service.service_amount -= 1 : service.service_amount"
                      class="button-change">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus"
                        class="svg-inline--fa fa-minus" role="img" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512">
                        <path fill="currentColor"
                          d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z">
                        </path>
                      </svg>
                    </span>
                    <input v-model="service.service_amount" type="text" class="form-input">
                    <span @click="service.service_amount < 4 ? service.service_amount += 1 : service.service_amount"
                      class="button-change">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus"
                        class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512">
                        <path fill="currentColor"
                          d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
                        </path>
                      </svg>
                    </span>
                    <span style="margin-left:15px">{{ service.name }}</span>
                  </div>
                  <div v-else class="form-wrap">
                    <span>{{ service.name }}</span>
                  </div>
                  <div @click="delete_service(service)" class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-gutter-20">
            <div class="col-12">
              <p class="meta-action" style="text-align:left">
                <a v-if="!feature_letter_coding" @click="add_additional_service" class="button button-gray">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>{{ $t('add_additional_service') }}</span>
                </a>
              </p>
            </div>
            <div class="col-12">
              <p v-if="selected_services && selected_services.length > 0" class="meta-action">
                <a @click="set_cart(false)" class="button button-red">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                    class="svg-inline--fa fa-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path fill="currentColor"
                      d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z">
                    </path>
                  </svg>
                  <span v-if="feature_letter_coding">{{ $t('continue_to_contact_data') }}</span>
                  <span v-else>{{ $t('continue_to_date_selection') }}</span>
                </a>
              </p>
            </div>
          </div>
        </div>

        <div v-if="current_step == 'show_solo_screen'">
          <h3>4. {{ $t('selected_services') }}</h3>

          <div class="solo-screen-wrap">
            <p>Sind die ausgewählten Leistungen für die selbe Person gedacht?<small
                style="display:block;margin-top:3px;opacity:0.7">(Bitte auf den jeweiligen Button klicken)</small></p>
          </div>

          <div class="row row-gutter-20">
            <div class="col-12">
              <p class="meta-action" style="margin: 0 0 10px 0;text-align:left">
                <a @click="set_cart('single')" class="button button-red button-100">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd"
                      d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                      clip-rule="evenodd" />
                  </svg>
                  <span>Für eine Person</span>
                </a>
              </p>
            </div>
            <div class="col-12">
              <p class="meta-action" style="margin: 0 0 10px 0;">
                <a @click="set_cart('multiple')" class="button button-red button-100">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path
                      d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                  </svg>
                  <span>Für mehrere Personen</span>
                </a>
              </p>
            </div>
          </div>
        </div>

        <div v-if="current_step == 'date'">
          <h3 v-if="feature_letter_coding">6. {{ $t('select_date') }}</h3>
          <h3 v-else>5. {{ $t('select_date') }}</h3>

          <div v-if="loading_busy_dates" class="loading-wrap">
            <span class="loading-spinner"></span>
          </div>
          <div v-else>
            <div class="cal-left">
              <v-date-picker v-model="cal_date" :min-date='cal_min_date' :max-date='cal_max_date'
                :disabled-dates="cal_disabled_dates" :first-day-of-week="2" locale="de" color="red" />
            </div>
            <div class="cal-right">
              <div v-if="cal_date">
                <div v-if="loading_slots" class="loading-wrap">
                  <span class="loading-spinner"></span>
                </div>
                <div v-else>
                  <p><strong>{{ $t('available_dates_at') }} {{ slots.date }}.</strong></p>
                  <div v-if="slots.slots && slots.slots.length == 0" class="alert alert-info">
                    <small>Es sind an diesem Tag keine freien Termine mehr vorhanden.</small>
                  </div>
                  <div v-else>
                    <ul class="available-times">
                      <li v-for="slot in slots.slots" v-bind:key="slot">
                        <input type="radio" :id="slot" :value="slot" v-model="cal_time">
                        <label :for="slot">
                          <span>{{ slot }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>{{ $t('select_date_in_calendar') }}.</p>
              </div>
            </div>
          </div>

          <p class="meta-action">
            <a @click="set_date" class="button button-red">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                class="svg-inline--fa fa-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z">
                </path>
              </svg>
              <span v-if="feature_letter_coding">{{ $t('continue_to_confirmation') }}</span>
              <span v-else>{{ $t('continue_to_contact_data') }}</span>
            </a>
          </p>
        </div>

        <div v-if="current_step == 'contact_details'">
          <h3 v-if="feature_letter_coding">5. {{ $t('add_contact_details') }}</h3>
          <h3 v-else>6. {{ $t('add_contact_details') }}</h3>

          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="form-wrap">
                <label for="first_name">{{ $t('first_name') }} <span class="rq">(* Pflichtfeld)</span></label>
                <input v-model="selections.contact_details.first_name" type="text" class="form-input" id="first_name">
              </div>
            </div>
            <div class="col-12">
              <div class="form-wrap">
                <label for="last_name">{{ $t('last_name') }} <span class="rq">(* Pflichtfeld)</span></label>
                <input v-model="selections.contact_details.last_name" type="text" class="form-input" id="last_name">
              </div>
            </div>
          </div>

          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="form-wrap">
                <label for="email">{{ $t('email_address') }} <span class="rq">(* Pflichtfeld)</span></label>
                <input v-model="selections.contact_details.email" type="text" class="form-input" id="email">
              </div>
            </div>
            <div class="col-12">
              <div class="form-wrap">
                <label for="phone">{{ $t('phone_number') }}</label>
                <input v-model="selections.contact_details.phone" type="text" class="form-input" id="phone">
              </div>
            </div>
          </div>

          <!--<div class="form-wrap">
            <label for="description">{{ $t('note') }}</label>
            <textarea v-model="selections.contact_details.description" id="description" name="description" rows="4" class="form-input"></textarea>
          </div>-->

          <p class="meta-action">
            <a @click="set_contact_details" class="button button-red">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                class="svg-inline--fa fa-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z">
                </path>
              </svg>
              <span v-if="feature_letter_coding">{{ $t('continue_to_date_selection') }}</span>
              <span v-else>{{ $t('continue_to_confirmation') }}</span>
            </a>
          </p>
        </div>

        <div v-if="current_step == 'confirm'" class="step-confirm">
          <h3>7. {{ $t('please_review_data') }}</h3>

          <div class="confirm-wrap">
            <p class="head">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-check"
                class="svg-inline--fa fa-user-check fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512">
                <path fill="currentColor"
                  d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z">
                </path>
              </svg>
              <span>{{ $t('services') }}</span>
            </p>
            <p v-for="service in selected_services" v-bind:key="service.name"><span v-if="service.name">{{ service.name
            }}</span></p>
          </div>

          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="confirm-wrap">
                <p class="head">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-day"
                    class="svg-inline--fa fa-calendar-day fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path fill="currentColor"
                      d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-96zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z">
                    </path>
                  </svg>
                  <span>{{ $t('date') }}</span>
                </p>
                <p>{{ appointment.date }}</p>
              </div>
            </div>
            <div class="col-12">
              <div class="confirm-wrap">
                <p class="head">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock"
                    class="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z">
                    </path>
                  </svg>
                  <span>{{ $t('time') }}</span>
                </p>
                <p>{{ appointment.time }} Uhr</p>
              </div>
            </div>
          </div>

          <div class="confirm-wrap">
            <p class="head">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user"
                class="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z">
                </path>
              </svg>
              <span>{{ $t('personal_data') }}</span>
            </p>
            <p>{{ appointment.contact_details.first_name }} {{ appointment.contact_details.last_name }}<span
                v-if="appointment.contact_details && appointment.contact_details.email">, E-Mail:
                {{ appointment.contact_details.email }}</span><span
                v-if="appointment.contact_details && appointment.contact_details.phone"> , Telefon:
                {{ appointment.contact_details.phone }}</span></p>
          </div>

          <div class="meta-action" style="margin-bottom:0">
            <div v-if="loading" class="loading-wrap" style="padding: 20px 0 0 0">
              <span class="loading-spinner"></span>
            </div>
            <div v-else>
              <a @click="create_appointment" class="button button-red button-100">{{ $t('create_appointment') }} ›</a>
            </div>
          </div>
        </div>

        <ul class="nav nav-steps">
          <li v-if="!appointment.location && current_step != 'category'">2. {{ $t('select_category') }}</li>
          <li v-if="!appointment.service && current_step != 'service'">3. {{ $t('select_service') }}</li>
          <li v-if="!selected_services.length > 0 && current_step != 'cart'">4. {{ $t('selected_services') }}</li>
          <li v-if="!feature_letter_coding && !appointment.date && current_step != 'date'">5. {{ $t('select_appointment')
          }}</li>
          <li v-if="feature_letter_coding && !appointment.contact_details && current_step != 'contact_details'">5. {{
            $t('enter_contact_details') }}</li>
          <li v-if="feature_letter_coding && !appointment.date && current_step != 'date'">6. {{ $t('select_appointment')
          }}</li>
          <li v-if="!feature_letter_coding && !appointment.contact_details && current_step != 'contact_details'">6. {{
            $t('enter_contact_details') }}</li>
          <li v-if="(!appointment.contact_details || !appointment.date) && current_step != 'confirm'">7. {{
            $t('book_appointment') }}</li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'new_appointment',
  computed: {
    nsc_lang: function () { return this.$store.getters.get_nsc_lang }
  },
  data() {
    return {
      maintenance: false,
      loading: false,
      loading_locations: true,
      loading_categories: true,
      loading_services: true,
      loading_slots: true,
      loading_busy_dates: true,
      loading_cart: false,
      languages: [],
      locations: [],
      categories: [],
      services: [],
      slots: [],
      current_step: 'location',
      selections: {
        location: null,
        category: null,
        service: null,
        contact_details: {}
      },
      appointment: {
        location: null,
        category: null,
        service: null,
        date: null,
        time: null,
        contact_details: null,
        service_amount: 1
      },
      cal_date: null,
      cal_time: null,
      cal_disabled_dates: [],
      cal_min_date: null,
      cal_max_date: null,
      appointment_created: false,
      selected_services: [],
      finished_cart: false,
      feature_letter_coding: false,
      skipSoloWindow: null
    }
  },
  methods: {
    get_lang() {
      this.$i18n.locale = this.$store.getters.get_nsc_lang;
    },
    async get_data() {
      this.loading_locations = true;
      await this.$http.get(process.env.VUE_APP_BASE_API + '/languages', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.languages = response.data.languages;
        });
      await this.$http.get(process.env.VUE_APP_BASE_API + '/locations', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.locations = response.data.locations;
          this.loading_locations = false;

          if (this.$route.query.standort) {
            var passed_location = null;
            this.locations.forEach(location => {
              if (location.name == this.$route.query.standort) {
                passed_location = location;
              }
            });
            if (passed_location) {
              this.appointment.location = passed_location;
              this.current_step = 'category'
              this.get_categories();
            }
          }

        });
      await this.$http.get(process.env.VUE_APP_BASE_API + '/service_dates', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          response.data.forEach(date => {
            if (date.weekdays) {
              this.cal_disabled_dates.push(date)
            } else {
              this.cal_disabled_dates.push({
                start: new Date(date.start),
                end: new Date(date.end)
              })
            }
          });
        });
      this.cal_min_date = new Date();
      var d = new Date();
      d.setMonth(d.getMonth() + 3);
      this.cal_max_date = d;
    },
    get_categories() {
      this.loading_categories = true;
      this.$http.get(process.env.VUE_APP_BASE_API + '/categories', {
        params: {
          location_id: this.appointment.location.id
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
        .then(response => {
          this.categories = response.data.categories;
          this.loading_categories = false;

          if (this.$route.query.kategorie) {
            var passed_category = null;
            this.categories.forEach(category => {
              if (category.name == this.$route.query.kategorie) {
                passed_category = category;
              }
            });
            if (passed_category) {
              this.appointment.category = passed_category;
              this.current_step = 'service'
              this.get_services();
            }
          }

        });
    },
    get_services() {
      this.loading_services = true;
      this.$http.get(process.env.VUE_APP_BASE_API + '/services', {
        params: {
          category_id: this.appointment.category.id
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
        .then(response => {
          this.services = response.data.services;
          this.services.forEach(service => {
            service.service_amount = 1;
          })
          this.loading_services = false;

          if (this.$route.query.leistung) {
            var passed_service = null;
            this.services.forEach(service => {
              if (service.name == this.$route.query.leistung) {
                passed_service = service;
              }
            });
            if (passed_service) {
              this.selected_services.push({
                name: passed_service.name,
                service_id: passed_service.id,
                service_amount: passed_service.service_amount,
                multi_select_possible: passed_service.multi_select_possible
              })
              this.appointment.service = passed_service;
              this.appointment.service_amount = passed_service.service_amount;
              if (this.$route.query.springen && this.$route.query.springen == "Ja") {
                this.appointment.date = null;
                this.appointment.time = null;
                this.cal_date = null;
                this.cal_time = null;
                this.get_busy_dates();
                this.finished_cart = true;
                this.current_step = 'date'
              } else if (this.$route.query.termin && this.$route.query.termin == "Ja") {
                this.finished_cart = true;
                this.current_step = 'date'
              } else {
                this.current_step = 'cart'
              }
            }
          }
        });
    },
    async get_slots(date) {
      this.loading_slots = true;
      this.slots = [];
      let busyDateObj = {
        date: date,
        selected_services: this.selected_services,
        is_solo_appointment: this.is_solo_appointment
      }
      if (this.appointment && this.appointment.contact_details && this.appointment.contact_details.last_name) {
        busyDateObj.match_type_value = this.appointment.contact_details.last_name.substring(0, 1)
      }
      await this.$http.post(process.env.VUE_APP_BASE_API + '/slots', busyDateObj, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.slots = response.data;
        });
      this.loading_slots = false;
    },
    set_location() {
      if (this.selections.location) {
        this.appointment.location = this.selections.location;
        this.feature_letter_coding = this.selections.location.feature_letter_coding
        this.current_step = 'category'
        this.get_categories();
      } else {
        this.$notify({
          title: "Bitte Standort auswählen",
          type: "error"
        });
      }
    },
    async get_busy_dates() {
      this.loading_busy_dates = true;
      let busyDateObj = {
        selected_services: this.selected_services,
        is_solo_appointment: this.is_solo_appointment
      }
      if (this.appointment && this.appointment.contact_details && this.appointment.contact_details.last_name) {
        busyDateObj.match_type_value = this.appointment.contact_details.last_name.substring(0, 1)
      }
      await this.$http.post(process.env.VUE_APP_BASE_API + '/busy_dates', busyDateObj, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          response.data.forEach(date => {
            this.cal_disabled_dates.push({
              start: new Date(date.start),
              end: new Date(date.end)
            })
          });
        });
      this.loading_busy_dates = false;
    },
    set_category() {
      if (this.selections.category) {
        this.appointment.category = this.selections.category;
        this.current_step = 'service'
        this.get_services();
      } else {
        this.$notify({
          title: "Bitte Kategorie auswählen",
          type: "error"
        });
      }
    },
    set_service() {
      if (this.selections.service) {
        this.selected_services.push({
          name: this.selections.service.name,
          service_id: this.selections.service.id,
          service_amount: this.selections.service.service_amount,
          multi_select_possible: this.selections.service.multi_select_possible
        })
        this.appointment.service = this.selections.service;
        this.appointment.service_amount = this.selections.service.service_amount;
        this.current_step = 'cart'
      } else {
        this.$notify({
          title: "Bitte Leistung auswählen",
          type: "error"
        });
      }
    },
    async set_cart(skipSoloWindow) {
      this.skipSoloWindow = skipSoloWindow
      if (this.feature_letter_coding) {
        this.current_step = 'contact_details'
        this.finished_cart = true;
      } else {
        // Check if solo time reduced
        let soloTimeCanBeReduced = false
        await this.$http.post(process.env.VUE_APP_BASE_API + '/check_solo_time', {
          services: this.selected_services
        })
          .then(response => {
            soloTimeCanBeReduced = response.data.solo_time_reduced
          });
        let showSoloScreen = false
        if (soloTimeCanBeReduced && !skipSoloWindow) {
          showSoloScreen = true
        }
        if (showSoloScreen) {
          this.current_step = 'show_solo_screen'
        } else {
          this.is_solo_appointment = false
          if (skipSoloWindow == "single") {
            this.is_solo_appointment = true
          }
          if (this.selected_services && this.selected_services.length > 0) {
            this.appointment.date = null;
            this.appointment.time = null;
            this.cal_date = null;
            this.cal_time = null;
            this.get_busy_dates();
            this.finished_cart = true;
            this.current_step = 'date'
          } else {
            this.$notify({
              title: "Bitte Leistung auswählen",
              type: "error"
            });
          }
        }
      }

      /*if (this.selections.service) {
        this.appointment.service = this.selections.service;
        this.appointment.service_amount = this.selections.service.service_amount;
        this.current_step = 'date'
      } else {
        this.$notify({
          title: "Bitte Leistung auswählen",
          type: "error"
        });
      }*/
    },
    add_additional_service() {
      this.selections.category = null;
      this.selections.service = null;
      this.selections.contact_details = {};
      this.appointment.category = null;
      this.appointment.service = null;
      this.appointment.date = null;
      this.appointment.time = null;
      this.current_step = 'category'
    },
    set_date() {
      if (this.slots.date && this.cal_time) {
        this.appointment.date = this.slots.date;
        this.appointment.time = this.cal_time;
        if (this.feature_letter_coding) {
          this.current_step = 'confirm'
        } else {
          this.current_step = 'contact_details'
        }
      } else {
        this.$notify({
          title: "Bitte Datum und Uhrzeit auswählen",
          type: "error"
        });
      }
    },
    async reset_cal_disabled_dates() {
      this.cal_disabled_dates = []
      await this.$http.get(process.env.VUE_APP_BASE_API + '/service_dates', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          response.data.forEach(date => {
            if (date.weekdays) {
              this.cal_disabled_dates.push(date)
            } else {
              this.cal_disabled_dates.push({
                start: new Date(date.start),
                end: new Date(date.end)
              })
            }
          });
        });
    },
    async set_contact_details() {
      if (this.selections && this.selections.contact_details && this.selections.contact_details.first_name && this.selections.contact_details.last_name && this.selections.contact_details.email) {
        this.appointment.contact_details = this.selections.contact_details;
        if (this.feature_letter_coding) {
          // Reset cal_disabled_dates
          await this.reset_cal_disabled_dates()
          // Check if solo time reduced
          let soloTimeCanBeReduced = false
          await this.$http.post(process.env.VUE_APP_BASE_API + '/check_solo_time', {
            services: this.selected_services
          })
            .then(response => {
              soloTimeCanBeReduced = response.data.solo_time_reduced
            });
          let showSoloScreen = false
          if (soloTimeCanBeReduced && !this.skipSoloWindow) {
            showSoloScreen = true
          }
          if (showSoloScreen) {
            this.current_step = 'show_solo_screen'
          } else {
            this.is_solo_appointment = false
            if (this.skipSoloWindow == "single") {
              this.is_solo_appointment = true
            }
            if (this.selected_services && this.selected_services.length > 0) {
              this.appointment.date = null;
              this.appointment.time = null;
              this.cal_date = null;
              this.cal_time = null;
              this.get_busy_dates();
              this.finished_cart = true;
              this.current_step = 'date'
            } else {
              this.$notify({
                title: "Bitte Leistung auswählen",
                type: "error"
              });
            }
          }
        } else {
          this.current_step = 'confirm'
        }
      } else {
        this.$notify({
          title: "Bitte alle Pflichtfelder ausfüllen",
          type: "error"
        });
      }
    },
    reset_appointment(type) {
      if (type == "location" || this.feature_letter_coding) {
        this.selections = {
          location: null,
          category: null,
          service: null,
          contact_details: {}
        }
        this.appointment = {
          location: null,
          category: null,
          service: null,
          date: null,
          time: null,
          contact_details: null
        }
        this.cal_date = null;
        this.cal_time = null;
        this.selected_services = [];
        this.current_step = 'location'
      } else if (type == "category") {
        this.selections.category = null;
        this.selections.service = null;
        this.selections.contact_details = {};
        this.appointment.category = null;
        this.appointment.service = null;
        this.appointment.date = null;
        this.appointment.time = null;
        this.cal_date = null;
        this.cal_time = null;
        this.current_step = 'category'
      } else if (type == "service") {
        this.selections.service = null;
        this.selections.contact_details = {};
        this.appointment.service = null;
        this.appointment.date = null;
        this.appointment.time = null;
        this.cal_date = null;
        this.cal_time = null;
        this.appointment.contact_details = null;
        this.current_step = 'service';
      } else if (type == "cart") {
        this.finished_cart = false;
        this.selections.contact_details = {};
        this.appointment.date = null;
        this.appointment.time = null;
        this.appointment.contact_details = null;
        this.cal_date = null;
        this.cal_time = null;
        this.current_step = 'cart';
      } else if (type == "date") {
        this.selections.contact_details = {};
        this.appointment.date = null;
        this.appointment.time = null;
        this.appointment.contact_details = null;
        this.cal_date = null;
        this.cal_time = null;
        this.current_step = 'date';
      } else if (type == "contact_details") {
        this.selections.contact_details = {};
        this.appointment.contact_details = null;
        this.current_step = 'contact_details';
      }
    },
    create_appointment() {
      var services = []
      for (let i = 0; i < this.selected_services.length; i += 1) {
        services.push({
          service_id: this.selected_services[i].service_id,
          service_amount: this.selected_services[i].service_amount
        })
      }
      this.loading = true;
      this.$http.post(process.env.VUE_APP_BASE_API + '/appointments', {
        location_id: this.appointment.location.id,
        category_id: this.appointment.category.id,
        first_name: this.appointment.contact_details.first_name,
        last_name: this.appointment.contact_details.last_name,
        description: this.appointment.contact_details.description,
        email: this.appointment.contact_details.email,
        date: this.appointment.date,
        time: this.appointment.time,
        services_attributes: services,
        is_solo_appointment: this.is_solo_appointment
      }, { headers: {} })
        .then(() => {
          this.appointment_created = true;
          this.loading = false;
        })
        .catch(error => {
          error.response.data.errors.forEach(val => {
            this.$notify({
              title: val,
              type: "error"
            });
          });
          this.loading = false;
        });
    },
    delete_service(service) {
      this.selected_services.splice(this.selected_services.indexOf(service), 1);
    },
    select_lang(lang) {
      localStorage.setItem('nsc_lang', lang);
      this.$store.commit('change_lang', lang);
      this.$i18n.locale = lang;
    },
    check_lang_class(lang) {
      if (this.$store.getters.get_nsc_lang == lang) {
        return 'selected';
      } else {
        return '';
      }
    }
  },
  mounted() {
    this.get_lang();
    this.get_data();
  },
  watch: {
    cal_date() {
      this.cal_time = null;
      this.get_slots(this.cal_date);
    }
  }
}
</script>
