<template>
  <div class="admin dashboard">
    <header class="admin-header cf">
      <h1>{{ $t("admin.counters") }}</h1>
      <div class="meta">
        <a @click="modal_add = true" class="button button-red">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="plus"
            class="svg-inline--fa fa-plus"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
            ></path>
          </svg>
          <span>Schalter hinzufügen</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>
      <div class="search-wrap">
        <input
          v-model="search_phrase"
          v-on:input="search_handler"
          type="text"
          class="form-search"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>

      <div v-if="search_loading" class="loading-wrap">
        <span class="loading-spinner"></span>
      </div>
      <div v-else>
        <div v-if="counters.length == 0" class="alert alert-info">
          <p>Aktuell sind noch keine Schalter vorhanden.</p>
        </div>
        <div v-else>
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Warteschlangen</th>
                <th>Mitarbeiter</th>
                <th>Leistungen</th>
                <th>Standort</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="counter in counters" v-bind:key="counter.id">
                <td width="15%">
                  {{ counter.name }}
                </td>
                <td width="15%">
                  <span
                    v-for="(waiting_line, i) in counter.waiting_lines"
                    v-bind:key="waiting_line.id"
                    style="display: inline-block"
                  >
                    <span
                      v-if="i < 2"
                      style="
                        display: inline-block;
                        margin-right: 10px;
                        background: #f6f6f6;
                        border: 1px solid #d0d0d0;
                        border-radius: 4px;
                        font-size: 12px;
                        padding: 5px 8px;
                      "
                      >{{ waiting_line.name }}</span
                    >
                  </span>
                </td>
                <td width="15%">
                  <span
                    v-for="(user, i) in counter.users"
                    v-bind:key="user.id"
                    style="display: inline-block"
                  >
                    <span
                      v-if="i < 2"
                      style="
                        display: inline-block;
                        margin-right: 10px;
                        background: #f6f6f6;
                        border: 1px solid #d0d0d0;
                        border-radius: 4px;
                        font-size: 12px;
                        padding: 5px 8px;
                      "
                      >{{ user.name }}</span
                    >
                  </span>
                </td>
                <td width="15%">
                  <span
                    v-for="(service, i) in counter.services"
                    v-bind:key="service.id"
                    style="display: inline-block"
                  >
                    <span
                      v-if="i < 2"
                      style="
                        display: inline-block;
                        margin-right: 10px;
                        background: #f6f6f6;
                        border: 1px solid #d0d0d0;
                        border-radius: 4px;
                        font-size: 12px;
                        padding: 5px 8px;
                      "
                      >{{ service.name }}</span
                    >
                  </span>
                  <span
                    v-if="counter.services > 4"
                    style="display: inline-block"
                    ><small>+{{ counter.services.length - 2 }}</small></span
                  >
                </td>
                <td width="15%">
                  <span
                    v-if="counter.location"
                    style="
                      display: inline-block;
                      margin-right: 10px;
                      background: #f6f6f6;
                      border: 1px solid #d0d0d0;
                      border-radius: 4px;
                      font-size: 12px;
                      padding: 5px 8px;
                    "
                    >{{ counter.location.name }}</span
                  >
                </td>
                <td width="15%">
                  <span
                    v-if="counter.status == 'disabled'"
                    class="status-hidden"
                  >
                    <span class="status-icon"></span>
                    <span class="status-text">Inaktiv</span>
                  </span>
                  <span
                    v-if="counter.status == 'enabled'"
                    class="status-visible"
                  >
                    <span class="status-icon"></span>
                    <span class="status-text">Aktiv</span>
                  </span>
                </td>
                <td width="10%" class="actions">
                  <a @click="edit_counter(counter)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg>
                  </a>
                  <a @click="delete_counter(counter)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <vue-final-modal
      classes="modal-wrap"
      content-class="modal-inner"
      v-model="modal_add"
    >
      <svg
        @click="modal_add = false"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
      <div class="modal-head">
        <h3>Schalter hinzufügen</h3>
      </div>
      <div class="modal-body">
        <div class="form-wrap select-wrap">
          <label for="status">Status</label>
          <select v-model="counter.status" class="form-input">
            <option key="hidden" value="disabled">Inaktiv</option>
            <option key="visible" value="enabled">Aktiv</option>
          </select>
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Name</label>
          <input
            v-model="counter.name"
            class="form-input input-grey"
            type="text"
            name="name"
            id="name"
          />
        </div>

        <div class="form-wrap select-wrap">
          <label for="location_id">Standort</label>
          <select v-model="counter.location_id" class="form-input">
            <option
              v-for="location in locations"
              v-bind:key="location.id"
              v-bind:value="location.id"
            >
              {{ location.name }}
            </option>
          </select>
        </div>

        <div class="form-wrap select-wrap">
          <label for="prevent_parallel_appointments"
            >Parallele Termine unterbinden?</label
          >
          <select
            v-model="counter.prevent_parallel_appointments"
            class="form-input"
          >
            <option :key="true" :value="true">Ja</option>
            <option :key="false" :value="false">Nein</option>
          </select>
        </div>
      </div>
      <div class="modal-meta">
        <a @click="create_counter" class="button button-red button-100"
          >Schalter erstellen ›</a
        >
      </div>
    </vue-final-modal>

    <vue-final-modal
      classes="modal-wrap"
      content-class="modal-inner"
      v-model="modal_edit"
    >
      <svg
        @click="modal_edit = false"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
      <div class="modal-head">
        <h3>Schalter bearbeiten</h3>
      </div>
      <div class="modal-body">
        <div class="form-wrap select-wrap">
          <label for="status">Status</label>
          <select v-model="selected_counter.status" class="form-input">
            <option key="hidden" value="disabled">Inaktiv</option>
            <option key="visible" value="enabled">Aktiv</option>
          </select>
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Name</label>
          <input
            v-model="selected_counter.name"
            class="form-input input-grey"
            type="text"
            name="name"
            id="name"
          />
        </div>

        <div class="form-wrap select-wrap">
          <label for="location_id">Standort</label>
          <select v-model="selected_counter.location_id" class="form-input">
            <option
              v-for="location in locations"
              v-bind:key="location.id"
              v-bind:value="location.id"
            >
              {{ location.name }}
            </option>
          </select>
        </div>

        <div class="form-wrap select-wrap">
          <label for="prevent_parallel_appointments"
            >Parallele Termine unterbinden?</label
          >
          <select
            v-model="selected_counter.prevent_parallel_appointments"
            class="form-input"
          >
            <option :key="true" :value="true">Ja</option>
            <option :key="false" :value="false">Nein</option>
          </select>
        </div>
      </div>
      <div class="modal-meta">
        <a @click="update_counter" class="button button-red button-100"
          >Schalter bearbeiten ›</a
        >
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
export default {
  name: "admin_counters",
  data() {
    return {
      loading: true,
      modal_add: false,
      modal_edit: false,
      counters: [],
      locations: [],
      counter: {},
      selected_counter: {},
      search_loading: false,
      search_phrase: "",
      searching: false,
    };
  },
  methods: {
    async get_data() {
      await this.$http
        .get(process.env.VUE_APP_BASE_API + "/admin/counters", {
          headers: { Authorization: this.$store.getters.get_token },
        })
        .then((response) => {
          this.counters = response.data.counters;
        });
      await this.$http
        .get(process.env.VUE_APP_BASE_API + "/admin/locations", {
          headers: { Authorization: this.$store.getters.get_token },
        })
        .then((response) => {
          this.locations = response.data.locations;
        });
      this.loading = false;
    },
    delete_counter(counter) {
      if (confirm("Wirklich löschen?")) {
        this.$http
          .delete(
            process.env.VUE_APP_BASE_API + "/admin/counters/" + counter.id,
            { headers: { Authorization: this.$store.getters.get_token } }
          )
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success",
            });
          });
      }
    },
    edit_counter(counter) {
      this.selected_counter = counter;
      this.modal_edit = true;
    },
    create_counter() {
      this.$http
        .post(process.env.VUE_APP_BASE_API + "/admin/counters", this.counter, {
          headers: { Authorization: this.$store.getters.get_token },
        })
        .then(() => {
          this.get_data();
          this.modal_add = false;
          this.counter = {};
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success",
          });
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    update_counter() {
      this.$http
        .patch(
          process.env.VUE_APP_BASE_API +
            "/admin/counters/" +
            this.selected_counter.id,
          this.selected_counter,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then(() => {
          this.get_data();
          this.modal_edit = false;
          this.$notify({
            title: "Erfolgreich gespeichert.",
            type: "success",
          });
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    search_handler() {
      if (this.searching == false) {
        this.search_loading = true;
        this.searching = true;
        this.$http
          .get(process.env.VUE_APP_BASE_API + "/admin/counters", {
            params: {
              search_phrase: this.search_phrase,
            },
            headers: { Authorization: this.$store.getters.get_token },
          })
          .then((response) => {
            this.counters = response.data.counters;
            this.search_loading = false;
            this.searching = false;
          });
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
